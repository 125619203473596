const LoanProgressBar = ({ current, type }) => {

    const stages = ["initial", 'approved', "pendingDisbursement", "active", "completed"];
    const stagesFull = ["In Review", 'Attach Facility Letter', "Pending Disbursement", "Active", "Completed"];

    const currentStageIndex = stages.indexOf(current);
    let progressBarWidth = 100 / (stages.length - 1) * currentStageIndex;

    return (
        <div className="w-full mb-12">
            <div className="flex justify-between mb-4">
                <span className="text-base font-medium text-gray-700 dark:text-white/[.7]">
                    {type} Application Progress
                </span>
                <span className="md:hidden lg:hidden text-sm font-medium text-gray-700 dark:text-white">
                    {progressBarWidth}%
                </span>
            </div>

            {/* Progress bar */}
            <div className="relative flex items-center w-full h-1.5 bg-gray-200 dark:bg-dark-page rounded-full">
                <div className="absolute h-2.5 bg-emerald-400 dark:bg-emerald-300 rounded-full" style={{ width: `${progressBarWidth}%` }}></div>
            </div>
            {/* Progress bar */}

            <div className="hidden md:flex justify-between mt-4">
                {stagesFull.map((stage, index) => (
                    <div key={index} className={`${index <= currentStageIndex && 'text-emerald-400 dark:text-emerald-300'} ${(index === currentStageIndex && index !== stagesFull.length - 1) && 'animate-pulse'}`}>
                        {stage}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LoanProgressBar;
