import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { FormInput, FormSelect, FormUpload, Button, Modal } from "components"
import { purposeOfLoanOpts, paymentPlanOpts, relationshipManagers, networkOptions, maxFileSize5, numberFormatter } from 'components/misc/constants';
// import { preCheckLoanEligibility } from '../../misc/loanPreChecks';
import LoanRequirement from './LoanRequirement';
import ReviewModal from 'components/Utils/ReviewModal';
import { useLocation, useUser } from 'hooks';
import { fetchRequests } from "https/requests";
import { useDispatch } from 'react-redux';
import { show } from 'providers/toastSlice';
import { useNavigate } from 'react-router-dom';
// import { XCircleIcon } from "@heroicons/react/24/solid";

function Credit({ client }) {
    const { city, country, region } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken } = useUser();
    
    const fullName = client?.otherNames + " " + client?.surname;

    let [isOpen, setIsOpen] = useState(false);
    const [isLoanReqOpen, setIsLoanReqOpen] = useState(false);
    const [reviewModalOpen, setReviewModalOpen] = useState(false);

    const closeModal = () => {
        setIsOpen(false)
    }
    
    const openModal = () => {
        setIsOpen(true)
    }

    const [purpose, setPurpose] = useState('');
    const [paymentPlan, setPaymentPlan] = useState('');
    const [preferredNetwork, setPreferredNetwork] = useState('');
    // const [residenceProof, setResidenceProof] = useState('');
    const [bankOrMomoStatement, setBankOrMomoStatement] = useState('');
    const [relManager, setRelManager] = useState('');
    const [guarantorFirstName, setGuarantorFirstName] = useState('');
    const [guarantorLastName, setGuarantorLastName] = useState('');
    const [guarantorPhone, setGuarantorPhone] = useState('');
    const [guarantorAddress, setGuarantorAddress] = useState('');
    const [guarantorRelationship, setGuarantorRelationship] = useState('');
    const guarantorIdType = 'GhanaCard';
    const [guarantorIdNumber, setGuarantorIdNumber] = useState('');
    const [guarantorDigitalAddress, setGuarantorDigitalAddress] = useState('');
    const [guarantorDob, setGuarantorDob] = useState('');
    const signedName = fullName;
    // const [preCheckData, setPreCheckData] = useState({}); 
    const [processing, setProcessing] = useState(false);
    const [results, setResults] = useState({});

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcessing(true);

        // check to see if files have been uploaded
        let bankFile = document.getElementById("bankOrMomoStatement");

        if(!bankFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach momo statement"
            }))
            return;
        }

        if(bankFile.files[0].size > maxFileSize5){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "File size too large. Maximum file size is 5MB"
            }))
            return;
        }

        let data = {
            purpose, paymentPlan, autoDeduction : "Mobile Money Auto-Deduction", preferredNetwork, bankOrMomoStatement, relManager, guarantorFirstName, guarantorLastName, guarantorPhone, guarantorAddress, guarantorRelationship, guarantorIdType, guarantorIdNumber, guarantorDigitalAddress, guarantorDob, signedName, city, region, country
        };

        // precheck loan application eligibility
        // let response = await preCheckLoanEligibility("cediscredit", data);
        // setPreCheckData({ ...response, data });

        // no need to precheck, just send data to backend and wait for response before showing modal
        let url = `${process.env.REACT_APP_STAGING_LOAN}/apply/cediscredit`;
        let headers = {
            "authorization" : `${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }

        const formData = new FormData();
        for(const [key, value] of Object.entries(data)){
            if(key === "otherDocs"){
                value.map((item, index) => (
                    formData.append(`otherDocument_${index + 1}`, item)
                ));
            }
            formData.append(`${key}`, value);
        }

        let res = await fetchRequests(url, "post", headers, {}, formData);
        setProcessing(false);

        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                state : true,
                message : `Your session has expired, please login to continue.`,
                type : "token"
            }))
            return;
        }

        // if(res?.err){
        //     dispatch(show({
        //         state : true,
        //         message : res?.err,
        //         type : "error"
        //     }))
        //     return;
        // }

        setResults(res);

        setTimeout(() => {
            setProcessing(false);
            openModal();
        }, 100);


        // setTimeout(() => {
        //     setProcessing(false);
        //     openModal();
        // }, 3000);
    }

    const closeReviewModal = () => {
        setReviewModalOpen(false);
        navigate("/dashboard");
    }

    // open loan requirements modal on page load
    useEffect(() => {
        setIsLoanReqOpen(true);
    },[]);


    return (
        <>
        <LoanRequirement 
            isOpen={isLoanReqOpen}
            setIsLoanReqOpen={setIsLoanReqOpen}
            type="CedisCredit"
            requirements={[
                "6 Months Mobile Money Statement (PDF)",
            ]}
        />

        <div className='display_card'>
            <form className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-2' onSubmit={handleRequest}>
                <div className='col-span-full'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Loan Information
                    </h3>
                </div>

                <FormSelect 
                    label='What is the purpose of the loan?'
                    name='purpose'
                    id="purpose"
                    content={purposeOfLoanOpts}
                    value={purpose}
                    setValue={setPurpose}
                    required={true}
                />

                <FormSelect 
                    label='What is the desired payment plan?'
                    name='paymentPlan'
                    id="paymentPlan"
                    content={paymentPlanOpts.slice(0, 4)}
                    value={paymentPlan}
                    setValue={setPaymentPlan}
                    required={true}
                />

                <FormSelect
                    label="Please select your phone network"
                    name="preferredNetwork"
                    id="preferredNetwork"
                    content={networkOptions.slice(0, 1)}
                    value={preferredNetwork}
                    setValue={setPreferredNetwork}
                    required={true}
                />

                <FormSelect 
                    label='Kindly select a relationship manager'
                    name='relManager'
                    id="relManager"
                    content={relationshipManagers}
                    value={relManager}
                    setValue={setRelManager}
                    required={true}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Guarantor Information
                    </h3>
                </div>

                <FormInput 
                    label="Guarantor's first name"
                    name='guarantorFirstName'
                    id="guarantorFirstName"
                    type='text'
                    value={guarantorFirstName}
                    setValue={setGuarantorFirstName}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's last name"
                    name='guarantorLastName'
                    id="guarantorLastName"
                    type='text'
                    value={guarantorLastName}
                    setValue={setGuarantorLastName}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's phone number"
                    name='guarantorPhone'
                    id="guarantorPhone"
                    type='text'
                    value={guarantorPhone}
                    setValue={setGuarantorPhone}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's address"
                    name='guarantorAddress'
                    id="guarantorAddress"
                    type='text'
                    value={guarantorAddress}
                    setValue={setGuarantorAddress}
                    required={true}
                />

                <FormInput 
                    label="Your relationship to guarantor"
                    name='guarantorRelationship'
                    id="guarantorRelationship"
                    type='text'
                    value={guarantorRelationship}
                    setValue={setGuarantorRelationship}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's GhanaCard number"
                    name='guarantorIdNumber'
                    id="guarantorIdNumber"
                    type='text'
                    value={guarantorIdNumber}
                    setValue={setGuarantorIdNumber}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's digital address"
                    name='guarantorDigitalAddress'
                    id="guarantorDigitalAddress"
                    type='text'
                    value={guarantorDigitalAddress}
                    setValue={setGuarantorDigitalAddress}
                    required={true}
                />

                <div>
                    <label htmlFor="guarantorDob" className='form-label'>
                        Guarantor's date of birth
                    </label>
                    <input 
                        type="date"
                        name={"guarantorDob"}
                        id={"guarantorDob"}
                        className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                        value={guarantorDob}
                        onChange={e => setGuarantorDob(e.target.value)}
                        min={"1930-01-01"}
                        max={new Date().toISOString().split('T')[0]}
                        required={true}
                    />
                </div>

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Files to upload
                    </h3>
                </div>

                <FormUpload 
                    label='Upload mobile money statement'
                    name='bankOrMomoStatement'
                    id="bankOrMomoStatement"
                    value={bankOrMomoStatement}
                    setValue={setBankOrMomoStatement}
                    required={true}
                />

                {/* <FormUpload 
                    label='Upload proof of residence'
                    name='residenceProof'
                    id="residenceProof"
                    value={residenceProof}
                    setValue={setResidenceProof}
                    required={false}
                /> */}

                <div className='col-span-full mt-10 p-10 bg-sky-50 rounded-md text-slate-700'>
                    <h3 className='text-2xl font-medium leading-6 mb-6'>
                        Authorization 
                    </h3>

                    <h4 className='text-lg font-medium leading-6 mb-2'>
                        Loan Authorization
                    </h4>   

                    <p className='mb-6'>
                        I, hereby apply for loans stated in Sections of this application the information I have provided here is to the best of my knowledge true and correct. I authorize CedisPay Micro-Credit Enterprice to verify the correctness of this information and also to obtain additional information if it deems necessary in evaluating my loan application.
                    </p>

                    <h4 className='text-lg font-medium leading-6 mb-2'>
                        Disclosure to Credit Reference Bureaus
                    </h4>   

                    <p className='mb-6'>
                        CedisPay will obtain information about you from the credit reference bureaus to check your credit status and identity. The bureaus will record our enquiries which may be seen by other institutions that make their own credit enquiries about you. CedisPay shall also disclose your credit transactions to credit reference bureaus in accordance with the <strong>Credit Reporting Regulations, 2020 (L.I 2394)</strong>
                    </p>

                    <h4 className='text-lg font-medium leading-6 mb-2'>
                        Consent to Electronic Disclosures
                    </h4>   

                    <p className='mb-3'>
                        By signing below, you agree to receive all disclosures and notices regarding your loan application electronically. You also agree that electronic signatures, whether digital or encrypted, are valid and binding.
                    </p>
                </div>

                <FormInput 
                    label='Signed Name'
                    name='signedName'
                    id="signedName"
                    type='text'
                    value={signedName}
                    disabled={true}
                />

                <div className="col-span-full mt-6">
                    <div className="inline-flex items-center gap-2">
                        <Button 
                            btnText={"Check eligibility"}
                            btnType={"submit"}
                            btnClasses={"application-button w-max"}
                            processing={processing}
                        />
                    </div>
                </div>

            </form>
        </div>

        <Modal isOpen={isOpen} closeModal={results.err ? closeModal : () => {}}>
            <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white dark:bg-dark-bg p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 dark:text-white/[.7] flex items-center mb-4"
                >
                    Status: <span className={`ml-1 px-3 py-1.5 text-white font-medium rounded-full text-xs capitalize ${results?.message ? 'bg-emerald-400' : results?.err ? 'bg-red-400' : ''}`}>
                        {results?.err ? "Application Rejected" : "Application Accepted"}
                    </span>
                </Dialog.Title>
                
                {results?.err ? (
                    <div className="mt-4 w-full inline-flex items-center bg-red-100 text-red-700 px-4 py-3 rounded-md">
                        <span className="font-medium text-sm">
                            {results?.err}
                        </span>
                    </div>
                ) : (
                <div className="mt-2">
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden border dark:border-white/[.2]">
                            <table className='min-w-full'>
                                <thead className='text-sm font-bold tracking-wide text-left text-gray-700 dark:text-white/[.7] uppercase border-b dark:bg-transparent dark:border-white/[.2]'>
                                    <tr>
                                        <th scope="col" className='px-4 py-3'>Item</th>
                                        <th scope="col" className='px-4 py-3'>Detail</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Eligible Loan Amount
                                        </td>
                                        <td className='px-4 py-3'>
                                            GHC {numberFormatter(results?.accountInfo?.amountGiven)}
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Credit Score
                                        </td>
                                        <td className='px-4 py-3'>
                                            {results?.score}%
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Monthly Interest Rate
                                        </td>
                                        <td className='px-4 py-3'>
                                            {results?.accountInfo?.interestRate}%
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Processing fee (one-time)
                                        </td>
                                        <td className='px-4 py-3'>
                                            GHC {numberFormatter(results?.accountInfo?.processingFee)}
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Insurance fee (one-time)
                                        </td>
                                        <td className='px-4 py-3'>
                                            GHC {numberFormatter(results?.accountInfo?.insuranceFee)}
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Amount to receive
                                        </td>
                                        <td className='px-4 py-3'>
                                            GHC {numberFormatter(results?.accountInfo?.amountGiven)}
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Repayment frequency
                                        </td>
                                        <td className='px-4 py-3'>
                                            {results?.paymentFreq}
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Repayment Amount
                                        </td>
                                        <td className='px-4 py-3'>
                                            {results?.accountInfo?.numPayments} payments of GHC {numberFormatter(results?.accountInfo?.premium)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="mt-2 w-full inline-flex items-center bg-emerald-100 text-emerald-700 px-4 py-3 rounded-md">
                        <span className="font-medium text-sm">
                            {results?.message}
                        </span>
                    </div>

                    <div className="mt-4 inline-flex items-center justify-between gap-4">
                        <Button 
                            btnText={"Review Application"}
                            btnType={"button"}
                            btnClasses={"form-button"}
                            btnFunction={() => setReviewModalOpen(true)}
                        />
                        
                        <Button 
                            btnText={"Close"}
                            btnType={"button"}
                            btnClasses={"inline-flex justify-center rounded-md bg-blue-100 px-4 py-2.5 text-sm font-medium text-blue-900 hover:bg-blue-200 mr-4"}
                            btnFunction={closeReviewModal}
                        />
                    </div>
                </div>
                )}
            </Dialog.Panel>
        </Modal>

        <ReviewModal 
            isOpen={reviewModalOpen} 
            isClose={setReviewModalOpen}
            client={signedName}
        />

        </>
    )
}

export default Credit