import { useState } from "react";
import { numberFormatter } from "../../components/misc/constants";
import { Button } from "components";
import { useUser } from "hooks";
import { fetchRequests } from "https/requests";
import { show } from "providers/toastSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import ReviewModal from "./ReviewModal";

function EligCheck({ status, data, loanType, closeModal, setReviewModalOpen }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken } = useUser();
    const [processing, setProcessing] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    // review modal
    const [isOpen, setIsOpen] = useState(false);

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcessing(true);
        if(error) setError(false);

        if(data.loanAmt === 0){
            setError("Your eligible amount is zero (GHC0), you need a minimum eligible amount of GHC1. Please try again later.");
            setProcessing(false);
            return;
        }

        let url = `${process.env.REACT_APP_STAGING_LOAN}/apply/${loanType}`;
        let headers = {
            "authorization" : `${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }

        const formData = new FormData();
        for(const [key, value] of Object.entries(data?.data)){
            if(key === "otherDocs"){
                value.map((item, index) => (
                    formData.append(`otherDocument_${index + 1}`, item)
                ));
            }
            formData.append(`${key}`, value);
        }

        let res = await fetchRequests(url, "post", headers, {}, formData);

        setProcessing(false);
        
        if(res?.code === 401){
			navigate("/login");
            closeModal();
            dispatch(show({
                state : true,
                message : `Your session has expired, please login to continue.`,
                type : "token"
            }))
            return;
        }

        if(res?.err){
            setError(res?.err);
            return;
        }
        
        if(res?.message){
            setSuccess(res?.message);
        }

        setTimeout(() => {
            closeModal();
        }, 1000);

        setTimeout(() => {
            setReviewModalOpen(true);
        }, 1300);
    }

    return (
        <>
        <ReviewModal 
            isOpen={isOpen} 
            isClose={setIsOpen}
            client={data.data.signedName}
        />

        {status === "accepted" ? (
            <>
                <div className="mt-2">
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden border dark:border-white/[.2]">
                            <table className='min-w-full'>
                                <thead className='text-sm font-bold tracking-wide text-left text-gray-700 dark:text-white/[.7] uppercase border-b dark:bg-transparent dark:border-white/[.2]'>
                                    <tr>
                                        <th scope="col" className='px-4 py-3'>Item</th>
                                        <th scope="col" className='px-4 py-3'>Detail</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Eligible Loan Amount
                                        </td>
                                        <td className='px-4 py-3'>
                                            GHC {numberFormatter(data?.loanAmt)}
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Credit Score
                                        </td>
                                        <td className='px-4 py-3'>
                                            {data?.score}%
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Monthly Interest Rate
                                        </td>
                                        <td className='px-4 py-3'>
                                            {data?.interestRate}%
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Processing fee (one-time)
                                        </td>
                                        <td className='px-4 py-3'>
                                            GHC {numberFormatter(data?.paymentObj?.processingFee)}
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Insurance fee (one-time)
                                        </td>
                                        <td className='px-4 py-3'>
                                            GHC {numberFormatter(data?.paymentObj?.insuranceFee)}
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Amount to receive
                                        </td>
                                        <td className='px-4 py-3'>
                                            GHC {numberFormatter(data?.paymentObj?.amountGiven)}
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Repayment frequency
                                        </td>
                                        <td className='px-4 py-3'>
                                            {data?.paymentFreq}
                                        </td>
                                    </tr>
                                    <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-normal tracking-wide text-gray-500 dark:text-white/[.5]">
                                        <td className='px-4 py-3'>
                                            Repayment Amount
                                        </td>
                                        <td className='px-4 py-3'>
                                            {data?.paymentObj?.numPayments} payments of GHC {numberFormatter(data?.paymentObj?.premium)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="mt-4 inline-flex items-center justify-between">
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md bg-blue-100 px-4 py-2.5 text-sm font-medium text-blue-900 hover:bg-blue-200 mr-4"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>

                    <Button 
                        btnText={"Submit application"}
                        btnClasses={"form-button"}
                        btnType={"button"}
                        btnFunction={handleRequest}
                        processing={processing}
                        disabled={status !== "accepted" ? true : false}
                    />
                </div>

                {error && (
                    <div className="mt-4 w-full inline-flex items-center bg-red-100 text-red-700 px-4 py-3 rounded-md">
                        <XCircleIcon className="h-5 w-5 mr-2 text-red-400" aria-hidden="true" />

                        <span className="font-mediumm text-sm">
                            {error}
                        </span>
                    </div>
                )}

                {success && (
                    <div className="mt-4 w-full inline-flex items-center bg-emerald-100 text-emerald-700 px-4 py-3 rounded-md">
                        <CheckCircleIcon className="h-5 w-5 mr-2 text-emerald-400" aria-hidden="true" />

                        <span className="font-medium text-sm">
                            {success}
                        </span>
                    </div>
                )}
                
            </>
        ) : (
            <div className="mt-4 w-full inline-flex items-center bg-red-100 text-red-700 px-4 py-3 rounded-md">
                <XCircleIcon className="h-5 w-5 mr-2 text-red-400" aria-hidden="true" />

                <span className="font-medium text-sm">
                    Your loan application was not accepted
                </span>
            </div>
        )}
        </>
    )

}

export default EligCheck